(function() {
	const switchTriggers = document.querySelectorAll('.switcher-item a');
	const switchItems    = document.querySelectorAll('.switcher-item');

	if (switchTriggers) {
		switchTriggers.forEach(trigger => {
			trigger.addEventListener('mouseover', event => {

				switchItems.forEach(item => {
					item.classList.remove('is-active');
				});

				trigger.parentElement.classList.add('is-active');
			});
		});
	}

})();
