/* video.js */
(function() {
	const triggers = document.querySelectorAll('.js-control');
	const items    = document.querySelectorAll('.js-remove');

	if (triggers) {
		triggers.forEach(trigger => {
			trigger.addEventListener('click', event => {

				items.forEach(item => {
					item.classList.add('is-hidden');
				});
			});
		});
	}

})();
