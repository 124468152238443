/**
 * Willers Post Filter
 */

const wwFilters = document.querySelectorAll('[data-id="ww-filter"]');
const wwFilterButtons = document.querySelectorAll('[data-id="ww-filter-button"]');
const wwFilterWrappers = document.querySelectorAll('[data-id="ww-filter-wrapper"]');

const filterElements = (fParent, wwFilter) => {
	let index = wwFilter.selectedIndex;
	let classNameToAdd = wwFilter.options[index].value;
	fParent.className = '';
	fParent.classList.add(classNameToAdd);
}

wwFilters.forEach(wwFilter => {
	const fParent = wwFilter.closest('[data-id="ww-filter-wrapper"]');
	wwFilter.addEventListener("change", () => {
		filterElements(fParent, wwFilter);
	});
})


/**
 *  Single Filter per Page
 *

const wwFilter = document.getElementById( 'ww-filter' );
const wwFilterButton = document.getElementById( 'ww-filter-button' );
const wwFilterWrapper = document.getElementById( 'ww-filter-wrapper' );

if( wwFilter ) {
	wwFilter.addEventListener("change", () => {
		filterElements();
	});
}

if( wwFilter ) {
	wwFilterButton.addEventListener("click", () => {
		filterElements();
	});
}
*/
