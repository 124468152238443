export function getSiblings(el) {

    // https://www.javascripttutorial.net/javascript-dom/javascript-siblings/
    // for collecting siblings
    let siblings = []; 

    // if no parent, return no sibling
    if(!el.parentNode) {
        return siblings;
    }

    // first child of the parent node
    let sibling  = el.parentNode.firstChild;

    // collecting siblings
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== el) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }

    return siblings;
};
