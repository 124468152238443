/**
 * Open Dropdown Navigation on Click / Tap
 * Toggle Button Status on Click / Tap
 */

const haveDropdownToggles = () => {
    dropdownToggles = document.querySelectorAll( '.dropdown-toggle' ) ;
    return ''!=dropdownToggles ? dropdownToggles : false;
}

const hideDropDowns = () => {
    if (!haveDropdownToggles()) {
        return false;
    }
    dropdownToggles.forEach(dropdownToggle => {
        let parent = dropdownToggle.closest('.nav-item');
        parent.classList.add( 'nav-item--dropdown--closed' );
    });
}

const toggleDropdown = () => {

    if (!haveDropdownToggles()) {
        return false;
    }

    dropdownToggles.forEach(dropdownToggle => {
      dropdownToggle.addEventListener('click', (event) => {
        let parent = dropdownToggle.closest('.nav-item');
        parent.classList.toggle( 'nav-item--dropdown--closed' );
      });
    });

    document.addEventListener('click', (event) => {
        let parent = event.target.closest('.nav-item--has-dropdown');
        if(!parent) {
            hideDropDowns();
        }
        if(parent) {
            if(parent.className.indexOf( 'nav-item--dropdown--closed' ) <= 0 ) { // not open
                hideDropDowns();
                parent.classList.remove( 'nav-item--dropdown--closed' );
            }
        }
    });

    window.addEventListener('keydown', (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      switch (event.key) {
        case 'Esc': // IE/Edge specific value
        case 'Escape':

          hideDropDowns();

          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }

      // Cancel the default action to avoid it being handled twice
      event.preventDefault();
    }, true);

}

toggleDropdown();
