/**
 * Open Main Navigation on Click / Tap
 * Toggle Button Status on Click / Tap
 */

const setupMenuToggle = () => {

    if( ! document.querySelector( '#main-nav-toggle' ) ) {
        return false;
    }
    if( ! document.querySelector( '#main-navigation' ) ) {
        return false;
    }

    const toggle = document.querySelector( '#main-nav-toggle' );
    const hamburger = document.querySelector( '#main-nav-toggle .hamburger' );
    const mainNav = document.querySelector( '#main-navigation > ul' );

    mainNav.classList.remove( 'is-active' );

    toggle.addEventListener( 'click', () => {
        event.preventDefault();
        hamburger.classList.toggle('is-active');
        mainNav.classList.toggle( 'is-active' );
    });
}

setupMenuToggle();
